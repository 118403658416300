// @flow

import React from "react";
import {
    Layout,
    InspectionPointLayout,
    InspectionPointRequests,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const Requests = (props: *) => (
    <Layout {...props}>
        <InspectionPointLayout>
            <InspectionPointRequests location={props.location} />
        </InspectionPointLayout>
    </Layout>
);

export default withPrivateRoute(
    Requests,
    "/inspectiepunt/:inspectionPointId/aanvragen",
);
